import { useEffect, useMemo, useState } from 'react';

type AsyncMemoState<T> = {
	loading: boolean;
	data: T | null;
	error: Error | null;
};

export const useMemoAsync = <T>(asyncFunction: () => Promise<T>, deps: React.DependencyList): AsyncMemoState<T> => {
	const [state, setState] = useState<AsyncMemoState<T>>({
		loading: true,
		data: null,
		error: null
	});
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		let isMounted = true;
		setState({ loading: true, data: null, error: null });
		asyncFunction()
			.then(data => {
				if (isMounted) {
					setState({ loading: false, data, error: null });
				}
			})
			.catch(error => {
				if (isMounted) {
					setState({ loading: false, data: null, error });
				}
			});
		return () => {
			isMounted = false;
		};
	}, deps);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	return useMemo(() => state, [state.loading, state.data, state.error]);
};

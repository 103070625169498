import { type FC, useRef, act, type HTMLProps, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import usePartySocket from 'partysocket/react';
import { type ActiveItem, ROLE_HOST, PartyToHostMessageSchema, type HostToPartyMessage } from '../party/types';
import type PartySocket from 'partysocket';

const StreamPlayer: FC<{ room: string; socket: PartySocket }> = ({ socket }) => {
	socket.onmessage = async message => {
		try {
			const payload = PartyToHostMessageSchema.parse(JSON.parse(message.data));
			console.log('Received message:', JSON.stringify(payload));
			switch (payload.type) {
				case 'INIT':
					setActiveItem(payload.data.activeItem);
					break;
				case 'ACTIVE_ITEM':
					setActiveItem(payload.data);
					break;
				case 'PLAY':
					setPlaying(true);
					break;
				case 'PAUSE':
					setPlaying(false);
					break;
				case 'SEEK':
					playerRef.current?.seekTo(payload.data.seconds, 'seconds');
					break;
				case 'QUIT':
					break;
			}
		} catch (e) {
			console.error('Error parsing message:', e, message.data);
		}
	};
	const sendMessage = (message: HostToPartyMessage) => {
		console.log('Sending message:', JSON.stringify(message));
		socket.send(JSON.stringify(message));
	};
	const playerRef = useRef<ReactPlayer>(null);
	const [playing, setPlaying] = useState(false);
	const [activeItem, setActiveItem] = useState<ActiveItem | null>(null);
	return (
		<main className="fixed inset-0 flex items-center justify-center bg-black">
			{activeItem ? (
				activeItem.type == 'image' ? (
					<ImagePlayer
						url={activeItem.url}
						duration={activeItem.duration ?? 10}
						playing={playing}
						onLoad={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
						onTimerProgress={(seconds: number) =>
							sendMessage({ type: 'PROGRESS', data: { id: activeItem.id, seconds } })
						}
						onTimerEnded={() => sendMessage({ type: 'ENDED', data: { id: activeItem.id } })}
						onTimerPlay={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
						onTimerPause={() => sendMessage({ type: 'PAUSED', data: { id: activeItem.id } })}
					/>
				) : (
					<ReactPlayer
						key={activeItem.id}
						ref={playerRef}
						width="100%"
						height="100%"
						url={activeItem.url}
						playing={playing}
						onPlay={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
						onPause={() => sendMessage({ type: 'PAUSED', data: { id: activeItem.id } })}
						onReady={() => {
							if (playerRef.current && activeItem.secondsPlayed)
								playerRef.current.seekTo(activeItem.secondsPlayed, 'seconds');
							sendMessage({ type: 'READY', data: { id: activeItem.id } });
						}}
						onDuration={duration =>
							sendMessage({
								type: 'DURATION',
								data: { id: activeItem.id, seconds: duration }
							})
						}
						onStart={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
						onProgress={({ playedSeconds }) =>
							sendMessage({
								type: 'PROGRESS',
								data: { id: activeItem.id, seconds: playedSeconds }
							})
						}
						onEnded={() => sendMessage({ type: 'ENDED', data: { id: activeItem.id } })}
						onError={e => console.error('error', e)}
					/>
				)
			) : (
				<>
					<video
						className="fixed inset-0 h-screen w-screen object-cover object-center"
						src="/clouds.mp4"
						autoPlay
						muted
						loop
					/>
					<div className="relative z-10 mix-blend-lighten">
						<img src="/splash.webp" width={300} height={300} />
					</div>
				</>
			)}
		</main>
	);
};

const ImagePlayer: FC<
	HTMLProps<HTMLImageElement> & {
		url: string;
		duration: number;
		playing: boolean;
		onTimerProgress: (seconds: number) => void;
		onTimerEnded: () => void;
		onTimerPlay: () => void;
		onTimerPause: () => void;
	}
> = ({ url, duration, playing, onTimerProgress, onTimerEnded, onTimerPause, onTimerPlay, ...props }) => {
	const timerRef = useRef<number | null>(null);
	const msPlayed = useRef(0);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		msPlayed.current = 0;
		timerRef.current && clearInterval(timerRef.current);
		timerRef.current = null;
	}, [url]);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (playing) {
			onTimerPlay();
			timerRef.current = setInterval(() => {
				msPlayed.current += 100;
				if (msPlayed.current >= duration * 1000) {
					clearInterval(timerRef.current!);
					onTimerProgress(duration);
					onTimerEnded();
				} else onTimerProgress(msPlayed.current / 1000);
			}, 100) as any;
		} else {
			onTimerPause();
			clearInterval(timerRef.current!);
		}
		return () => clearInterval(timerRef.current!);
	}, [playing]);
	return <img src={url} className="fixed inset-0 h-screen w-screen object-contain object-center" {...props} />;
};
export default StreamPlayer;

import { createRoot } from 'react-dom/client';
import { useEffect, type FC } from 'react';
import StreamPlayer from './StreamPlayer';
import SoundboardPlayer from './SoundboardPlayer';
import usePartySocket from 'partysocket/react';
import { PartyToHostMessageSchema, ROLE_HOST, type HostToPartyMessage } from '../party/types';
import SoundsPrecache from '../data/SoundsPrecache.json';

const App: FC = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const room = queryParams.get('room') ?? 'debug';
	const socket = usePartySocket({
		host: 'sup-stream-party.shahruz.partykit.dev',
		party: 'main',
		room,
		query: { role: ROLE_HOST }
	});

	return (
		<>
			<StreamPlayer room={room} socket={socket} />
			<SoundboardPlayer room={room} socket={socket} />
			<SoundsPreloader />
		</>
	);
};

const SoundsPreloader: FC = () => {
	// Preload all sounds
	// Emoji list manually stored currently, last updated June 26 2024
	// Total size of downloads: 22.5mb
	useEffect(() => {
		(async () => {
			for (const sound of SoundsPrecache) {
				await fetch(sound.audioUrl);
			}
		})();
	}, []);
	return null;
};

createRoot(document.getElementById('app')!).render(<App />);

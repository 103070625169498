import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react';
import {
	LiveKitRoom,
	useLiveKitRoom,
	useLocalParticipant,
	useRemoteParticipants,
	useRoomContext
} from '@livekit/components-react';
import { useMemoAsync } from '../lib/useMemoAsync';
import { ConnectionState, Track, type Participant } from 'livekit-client';
import type PartySocket from 'partysocket';
import type { HostToPartyMessage } from '../party/types';

const SoundboardPlayerWrapper: FC<{ room: string; socket: PartySocket }> = ({ room, socket }) => {
	const token = useMemoAsync(async () => {
		console.log('fetching soundboard token');
		const soundboardToken = await fetch(
			`https://sup-stream-party.shahruz.partykit.dev/parties/main/${room}?soundboard=true`
		).then(res => res.text());
		return soundboardToken;
	}, [room]);
	if (!token.data) return null;
	return (
		<LiveKitRoom serverUrl="wss://sup-5x5oulgj.livekit.cloud" token={token.data} connect>
			<SoundboardPlayer socket={socket} />
		</LiveKitRoom>
	);
};

interface ParticipantSoundboardChannel {
	destination: MediaStreamAudioDestinationNode;
	track: MediaStreamTrack;
	nowPlaying: AudioBufferSourceNode | null;
}

const SoundboardPlayer: FC<{ socket: PartySocket }> = ({ socket }) => {
	const contextRef = useRef<AudioContext | null>(null);
	const participantsRef = useRef<Record<string, ParticipantSoundboardChannel>>({});
	const room = useRoomContext();
	const { localParticipant } = useLocalParticipant();

	useEffect(() => {
		contextRef.current = new AudioContext();
	}, []);

	const initAudioForParticipant = useCallback(
		async (userId: string) => {
			const destination = contextRef.current!.createMediaStreamDestination();
			const track = destination.stream.getAudioTracks()[0];
			await localParticipant.publishTrack(track, {
				name: `soundboard-${userId}`,
				source: Track.Source.Unknown
			});
			participantsRef.current[userId] = { destination, track, nowPlaying: null };
		},
		[localParticipant]
	);

	const play = useCallback(
		async (userId: string, url: string): Promise<void> => {
			if (!contextRef.current) return;
			const response = await fetch(url);
			const arrayBuffer = await response.arrayBuffer();
			const data = await contextRef.current.decodeAudioData(arrayBuffer);
			if (!participantsRef.current[userId]) await initAudioForParticipant(userId);
			return new Promise<void>(async resolve => {
				if (!participantsRef.current[userId]) return;
				if (participantsRef.current[userId].nowPlaying) participantsRef.current[userId].nowPlaying.stop();
				const source = contextRef.current!.createBufferSource();
				participantsRef.current[userId].nowPlaying = source;
				source.buffer = data;
				source.connect(participantsRef.current[userId].destination);
				source.onended = () => {
					if (participantsRef.current[userId]) participantsRef.current[userId].nowPlaying = null;
					resolve();
				};
				source.start();
			});
		},
		[initAudioForParticipant]
	);

	const participants = useRemoteParticipants();
	const prevParticipantsMetadataRef = useRef<Record<string, string>>({});

	const sendMessage = useCallback(
		(message: HostToPartyMessage) => {
			console.log('Sending message:', JSON.stringify(message));
			socket.send(JSON.stringify(message));
		},
		[socket]
	);

	useEffect(() => {
		participants.forEach(participant => {
			if (participant.metadata) {
				const prevParticipant = prevParticipantsMetadataRef.current[participant.identity];
				if (prevParticipant && prevParticipant === participant.metadata) return;
				prevParticipantsMetadataRef.current[participant.identity] = participant.metadata;
				console.log('Participant metadata changed:', participant.metadata);
				try {
					const metadata: {
						soundId?: string;
						audioUrl?: string;
						enableGlobalSoundboard?: boolean;
						started?: number;
					} = JSON.parse(participant.metadata);
					console.log('Parsed metadata:', metadata);
					if (metadata.enableGlobalSoundboard && metadata.soundId && metadata.audioUrl) {
						console.log('Playing sound for participant:', participant.identity);
						play(participant.identity, metadata.audioUrl).then(() => {
							sendMessage({
								type: 'SOUNDBOARD_PLAY_ENDED',
								data: {
									userId: participant.identity,
									soundId: metadata.soundId!
								}
							});
						});
					}
				} catch (error) {
					console.error('Error parsing participant metadata:', error);
				}
			}
		});
	}, [participants, play, sendMessage]);

	return null;
};

export default SoundboardPlayerWrapper;
